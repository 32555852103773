@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-width: 280px;
  --navbar-right: -280px;
  --animated-speed: 0.4s;
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-weight: normal;
  src: url('/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('/fonts/OpenSans-Bold.ttf');
}

* {
  font-family: 'Open Sans', sans-serif;
}

.logo {
  width: 100px;
  height: auto;
}

.header {
  position: relative;
}

.header::before {
  transition: 1.5s;
  background-image: url('/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  filter: brightness(0.7) blur(0.5px) opacity(85%);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.text-shadow {
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.text-shadow-nav {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.submit {
  background-color: transparent;
  background-image: url('/images/search.png');
  background-repeat: no-repeat;
  background-size: 2rem;
  max-width: 1000px;
  border: none;
  bottom: -0.05rem;
  cursor: pointer;
  display: block;
  height: 3rem;
  opacity: 0.6;
  position: absolute;
  right: 0.2rem;
  width: 3rem;
}

.submit:focus {
  outline: none;
}

.card > div > img {
  transition: transform 0.3s ease-in-out;
}

.card:hover > div > img {
  transform: scale(1.1);
}

/* GALERÍA */
.image-gallery-image,
.image-gallery-thumbnail-image {
  opacity: 0.9;
}

/* PAGINATION */
.pagination {
  display: flex;
  justify-content: center;
}

/* MARKDOWN */
.markdown ul {
  list-style: disc;
  margin-left: 25px;
}
.markdown p {
  margin: 15px 0;
}

/* NAVBAR */
.navbar {
  border: 0;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  right: var(--navbar-right);
  top: 0;
  width: var(--navbar-width);
  z-index: 2;
}

.navbar::-webkit-scrollbar {
  display: none;
}

.navbar-open {
  animation-duration: var(--animated-speed);
  animation-name: navbarOpen;
  right: 0;
}

@keyframes navbarOpen {
  0% {
    right: var(--navbar-right);
  }
  100% {
    right: 0;
  }
}

.navbar-close {
  animation-duration: var(--animated-speed);
  animation-name: navbarClose;
  right: var(--navbar-right);
}

@keyframes navbarClose {
  0% {
    right: 0;
  }
  100% {
    right: var(--navbar-right);
  }
}

/* ANIMACIÓN */
.animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ANIMACIÓN SUBMIT */
.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* SPINNER LOADING */
.lds-dual-ring {
  display: inline-block;
  width: 100%;
}

.lds-dual-ring:after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  margin: auto;
  border-radius: 50%;
  border: 6px solid #f87171;
  border-color: #9ca3af transparent #f87171 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MEDIA QUERY */
@media (min-width: 768px) {
  header nav img {
    width: 210px;
  }
}
